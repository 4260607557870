
import React from 'react'
import { I18n } from '@front/volcanion'

import { makeStyles } from '@mui/styles'
import styles from './styles'

import { IconButton, Button, Backdrop, Box, Typography, Fade } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import TimelineIcon from '@mui/icons-material/Timeline'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const useStyles = makeStyles(styles)

const WaitPage = props => {
  const classes = useStyles(props)
  const {
    isWaiting,
    handleClickHistory,
    handleClickCreateOrder,
    handleClickReturnHome
  } = props
  return (<Box >
    <Box sx={{ ml: 2 }}>
      <IconButton color='primary' classes={{ root: classes.button }} onClick={handleClickReturnHome} sx={{ 'z-index': 2 }}>
        <ArrowBackIcon fontSize='large' sx={{ "&:hover": { color: 'white' } }} />
      </IconButton>
    </Box>
    <Backdrop open={isWaiting} classes={{ root: classes.backdrop }} sx={{ 'z-index': 1 }}>
      <Fade in timeout={2000}>
        <Box sx={{ alignItems: 'center', justifyContent: 'center', h: 1, w: 1 }}>
          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography sx={{ fontSize: 'h1.fontSize', color: 'white', fontWeight: 200, letterSpacing: 3 }}>
              {I18n.t('order.wait_call')}
            </Typography>
          </Box>
          <Box sx={{ mb: 5, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='contained'
              startIcon={<AddIcon />}
              sx={{ bgcolor: 'white', color: 'primary.main', "&:hover": { color: "white", bgcolor: 'primary.main' } }}
              size='large'
              onClick={handleClickCreateOrder}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 28, letterSpacing: 2 }}>
                {I18n.t('order.create')}
              </Typography>
            </Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='contained'
              startIcon={<TimelineIcon />}
              sx={{ bgcolor: 'white', color: 'primary.main', "&:hover": { color: "white", bgcolor: 'primary.main' } }}
              size='large'
              onClick={handleClickHistory}
            >
              <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                {I18n.t('history.operator')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Fade>
    </Backdrop>
  </Box >)
}

export default React.memo(WaitPage)
