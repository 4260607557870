import React from 'react'
import { hooks } from '@front/volcanion'


const withContainer = Component => ({ initial_toggle }) => {
  const billing_status_list = hooks.useBillingStatusList()
  const order_status_list = hooks.useOrderStatusList()
  const transport_status_list = hooks.useTransportStatusList()

  const [company, contract] = hooks.useFieldValues(['company', 'contract'])
  const mergedProps = {
    initial_toggle,
    company,
    contract,
    billing_status_list,
    order_status_list,
    transport_status_list,
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
