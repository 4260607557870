
import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Step, Box } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from './Section/Primary'
import GeoSection from './Section/Geo'
import ScheduleSection from './Section/Schedule'
import RateParameterSection from './Section/RateParameter'

const Pricerule = ({ companyContractLabel, company, companycontract_id, ...props }) => <>
  <Box className='safearea'>
    <Grid container justifyContent={'center'} rowSpacing={1}>
      <Grid item xs={8} >
        {
          !!companycontract_id ?
            <FormHeader
              subTitle={I18n.t('link.contract')}
              onLinkClick={() => window.open(`/company/${company}/contract/${companycontract_id}`, '_blank')}
              linkText={companyContractLabel}
            /> : <FormHeader />
        }
      </Grid>
      <Grid item xs={8} >
        <GridStepper groupsCount={[4]} activeStep={0} orientation={'vertical'}>
          <Step> <PrimarySection /> </Step>
          <Step> <ScheduleSection /> </Step>
          <Step> <GeoSection /> </Step>
          <Step> <RateParameterSection />  </Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(Pricerule)
