import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { poitype_id, } = hooks.useParams()

  const formProps = {
    name: 'poitype_form',
    model_name: 'poitype',
    record_id: poitype_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'name_trkey',
      'reservationinfo.name_trkey',
      'reservationinfo.origin_trkey',
      'reservationinfo.arrival_trkey',
    ],
    initialState: { isReadOnly: !!poitype_id }
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
