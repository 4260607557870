import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell><TextCell value={_.get(row, 'name_translated')} /></TableCell>
  <TableCell><TextCell value={!!_.get(row, 'active') ? I18n.t('status.active') : I18n.t('status.inactive')} /></TableCell>
</>

export default React.memo(Row)
