
import Aux from './Aux'
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const hasCompanyContract = !_.isEmpty(record?.companycontract_noshows)
      return {
        name: _.get(record, 'name'),
        fixed_rate: _.get(record, 'fixed_rate'),
        max_rate: _.get(record, 'max_rate'),
        driver_manual_allowed: _.get(record, 'driver_manual_allowed'),
        apply_waiting_time: _.get(record, 'apply_waiting_time'),
        priority: _.get(record, 'priority'),
        commercial_packages: !!hasCompanyContract
          ? _.map(_.get(record, 'companycontract_noshows'), 'commercial_package')
          : _.map(_.get(record, 'commercialpackage_noshows'), 'commercial_package'),
        mode: !!hasCompanyContract
          ? _.get(_.head(_.get(record, 'companycontract_noshows')), 'mode')
          : _.get(_.head(_.get(record, 'commercialpackage_noshows')), 'mode'),
        commercial_formula: !!hasCompanyContract
          ? _.get(_.head(_.get(record, 'companycontract_noshows')), 'commercial_formula')
          : _.get(_.head(_.get(record, 'commercialpackage_noshows')), 'commercial_formula'),
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      const hasCompanyContract = !!state?.companycontract_id
      const hasCommercialPackage = !!state?.commercial_package_id
      return {
        name: _.get(values, 'name'),
        fixed_rate: _.get(values, 'fixed_rate'),
        max_rate: _.get(values, 'max_rate'),
        driver_manual_allowed: _.get(values, 'driver_manual_allowed'),
        apply_waiting_time: _.get(values, 'apply_waiting_time'),
        priority: _.get(values, 'priority'),
        companycontract_noshows: !!hasCompanyContract
          ? Aux.exportCompanycontractNoShow(values, state)
          : undefined,
        commercialpackage_noshows: !!hasCommercialPackage
          ? Aux.exportCommercialPackageNoshow(values, state)
          : undefined,
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name'),
        companycontract_id: _.get(_.head(record?.companycontract_noshows), 'company_contract'),
        companycontract_noshows: record?.companycontract_noshows,
        commercial_package_id: _.get(_.head(record?.commercialpackage_noshows), 'commercial_package'),
        commercialpackage_noshows: record?.commercialpackage_noshows,
      }
    }
  }

  static getEmptyStateHandler(companycontract_id, commercial_package_id) {
    return function getEmptyState() {
      return {
        companycontract_id,
        commercial_package_id,
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
}

export default Callbacks
