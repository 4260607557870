class Callbacks {
  static handleOnAddressResolve(setFormValues) {
    return function addressResolve(details) {
      setFormValues([{ field: 'coordinates', value: _.get(details, 'coordinates') }])
    }
  }


  static handleOnMapClick(setFormValues, isReadOnly) {
    return function onMapClick(event) {
      if (!isReadOnly)
        setFormValues([
          { field: 'meeting_point_coordinates.lat', value: event?.latlng?.lat },
          { field: 'meeting_point_coordinates.lng', value: event?.latlng?.lng }
        ])
    }
  }
}

export default Callbacks