import React from 'react'
import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { I18n } from '@front/volcanion/'

import {
  SingleOptionSelectorField,
  TextField,
  ModelMenuSelectorField
} from '@front/squirtle/'

const Row1 = ({ onTypeChange, display_poi_client_id }) => {

  return (
    <Grid container item xs={12} spacing={1} alignItems='flex-end'>
      <Grid item xs={1}>
        <SingleOptionSelectorField
          name='active'
          label={I18n.t('active')}
        />
      </Grid>
      <Grid item xs={3}>
        <ModelMenuSelectorField
          name='type'
          label={I18n.t('type.label', { count: 1 })}
          model_name='poitype'
          labelKeys={['name_translated']}
          searchMode={'search'}
          loadOnMount
          config={{ force_filter: { active: true } }}
          required
          afterChange={onTypeChange}
        />
      </Grid>
      <Grid item xs={1} hidden={!display_poi_client_id}>
        <TextField
          name={'poi_client_id'}
          label={I18n.t('poi.client_id')}
          type='number'
        />
      </Grid>
      <Grid item xs>
        <SingleOptionSelectorField
          name='required_additional_info'
          label={I18n.t('poi.required_additional_info')}
        />
      </Grid>
    </Grid>
  )
}

const PrimarySection = (props) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 3, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
