
import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [companycontract_id] = hooks.useFormState('companycontract_id')
  const [companycontract] = hooks.useModel('companycontract', [companycontract_id], { single: true })
  const authorized_formulas = companycontract?.formula

  const mergedProps = {
    authorized_formulas
  }
  return <Component {...mergedProps} />
}

export default withContainer
