class Callbacks {
  static inboundCallHandler(setForm, searchOrder, markCallAsRead, setIsWaiting, navigate, setCallId, clearRouterState) {
    return async function inboundCallHandler(inboundcall, caller_config, phone) {
      if (_.get(inboundcall, 'call_id')) {
        const orders = await searchOrder({ info: { call_id: _.get(inboundcall, 'call_id') } })
        if (!_.isEmpty(orders)) {
          const order = _.head(orders)
          return navigate(`/order/${order.order_id}`)
        }
        else {
          setCallId(_.get(inboundcall, 'call_id'))
        }
      }
      const final_gsm = phone || _.get(caller_config, 'context.gsm') || _.get(inboundcall, 'caller_phone')
      if (!!final_gsm) {
        const updates = _.compact(_.flatten([
          { field: 'user.calling_number', value: final_gsm },
          { field: 'user.application', value: _.get(caller_config, 'context.application') },
          { field: 'commercial_formula', value: _.get(caller_config, 'context.commercial_formula') },
          { field: 'load_type', value: _.get(caller_config, 'context.load_type') },
          { field: 'call_id', value: _.get(inboundcall, 'call_id') },
          { field: 'user.gsm', value: final_gsm }
        ]))
        setForm(updates)
      }
      setIsWaiting(false)
      clearRouterState()
      return markCallAsRead({ inbound_call_id: _.get(inboundcall, 'inbound_call_id') })
    }
  }
}

export default Callbacks
