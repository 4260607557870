import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Poi from './Details'
import PoiList from './List'

const PoiPage = props =>
  <Routes>
    <Route index element={<PoiList />} />
    <Route path='/create' element={<Poi />} />
    <Route path='/:poi_id' element={<Poi />} />
  </Routes>

export default React.memo(PoiPage)
