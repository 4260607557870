import React from 'react'
import { ModelAutocompleteField } from "@front/squirtle"
import { UserUtils } from "@front/volcanion/utils"
import { Grid, Typography } from "@mui/material"
import DriverComponent from '@abra/elements/Dispatch/Driver'
import { I18n } from '@front/volcanion'

const Row1 = props =>
  <Grid item xs={12}>
    <Typography fontSize={16} color='primary.main' fontWeight={600}>
      {I18n.t('dispatch.group.select_driver')}
    </Typography>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} justifyContent='center'>
    <ModelAutocompleteField
      name={'user_id'}
      label={I18n.t('map.manual_dispatch.short')}
      model_name={'user'}
      searchMode='search'
      searchQuery={'='}
      autocompleteMutator={q => ({
        or: [
          { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
          { info: { first_name: { startsWith: _.split(q, ' ') } } },
          { info: { last_name: { startsWith: _.split(q, ' ') } } }]
      })}
      labelKeys={['label']}
      multiple={false}
      loadOnFocus={false}
      config={{
        forced_filter: { driverinfo: { '!': null }, status: [{ name: 'active' }] },
        populate: ['info', 'customerinfo'],
        mutator: user => ({
          label: UserUtils.getFormatedName(
            { ...user?.info, designation: user?.customerinfo?.name },
            { prefix: user?.info?.user_client_id }
          ),
          ...user
        })
      }}
      selectSingleOption
      debounceTime={400}
      required
    />
  </Grid>

const Driver = props => {
  return (
    <Grid container rowSpacing={2} alignItems='center' sx={{ backgroundColor: 'white', p: 2 }}>
      <Row1 />
      <Row2 />
      <Grid item xs={12}>
        <DriverComponent />
      </Grid>
    </Grid >
  )
}

export default Driver
