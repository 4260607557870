import React, { useMemo } from 'react'

const withContainer = Component => props => {

  const translations = useMemo(() => [
    { name: 'reservationinfo.name_trkey', labelKey: 'reservationinfo.name', xs: 3 },
    { name: 'reservationinfo.origin_trkey', labelKey: 'reservationinfo.origin', xs: 3 },
    { name: 'reservationinfo.arrival_trkey', labelKey: 'reservationinfo.arrival', xs: 4 }
  ], [])

  const mergedProps = {
    translations
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
