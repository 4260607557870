
import React, { useEffect } from 'react'

import { hooks } from '@front/volcanion/'

const withContainer = Component => (props) => {
  const rateparameter_id = hooks.useFieldValue('rateparameter.rateparameter_id')
  const [rateparameter] = hooks.useModel('rateparameter', [rateparameter_id], { single: true })
  const setFormValues = hooks.useFormChange()
  const [isReadOnly] = hooks.useFormState('isReadOnly')

  useEffect(() => {
    if (!isReadOnly) {
      setFormValues([
        { field: 'rateparameter', value: rateparameter }
      ])
    }
  }, [isReadOnly, _.get(rateparameter, 'rateparameter_id')])

  const mergedProps = {
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
