class Callbacks {
  static handleSubmitMultipleDispatch(dispatchJobs) {
    return async function submitMultipleDispatch(values, extra, meta, state) {
      await dispatchJobs({
        user_id: values.user_id,
        order_ids: state.order_ids,
        options: { skip_blacklist: true }
      })
    }
  }
}

export default Callbacks
