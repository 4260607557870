import { DialogContent, FormDialog } from '@front/squirtle'
import React from 'react'

import MultipleDispatch from './MultipleDispatch'

const Popup = ({ submitMultipleDispatch }) => (
  <FormDialog
    name={'multi_dispatch'}
    maxWidth='xl'
    formProps={{
      onSubmit: submitMultipleDispatch,
    }}
  >
    <DialogContent>
      <MultipleDispatch />
    </DialogContent>
  </FormDialog>
)

export default React.memo(Popup)
