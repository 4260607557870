import React from 'react'
import { Routes, Route } from "@front/squirtle"
import OrderList from './List'
import Wait from './Wait'
import OrderDetails from './Details'

const OrderPage = (props) => {
  return (
    <Routes>
      <Route index element={<OrderList />} />
      <Route path='/create' element={<Wait />} />
      <Route path='/:order_id' element={<OrderDetails />} />
    </Routes>
  )
}

export default React.memo(OrderPage)
