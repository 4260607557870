import React from 'react'
import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { I18n } from '@front/volcanion'

import {
  Icon,
  MapFactory,
  NumberField,
  AddressAutocompleteField
} from '@front/squirtle'

const Row1 = ({ onAddressResolve }) => (
  <Grid container xs={12} spacing={2} alignItems={'center'}>
    <Grid item>
      <Icon icon='location_city' />
    </Grid>
    <Grid item xs={6}>
      <AddressAutocompleteField
        name={'address'}
        label={I18n.t('address.label', { count: 1 })}
        noOptionsText={I18n.t('no_result')}
        resolveAddress
        onAddressResolve={onAddressResolve}
        required
        initialConfig={{
          includePOI: false
        }}
      />
    </Grid>
  </Grid>
)

const Row2 = (props) => (
  <Grid container xs={12} spacing={2}>
    <Grid item>
      <Icon icon='get_app' />
    </Grid>
    <Grid item xs={3}>
      <NumberField
        name='meeting_point_coordinates.lat'
        label={I18n.t('address.meeting_point_coordinates')}
        type='float'
        InputProps={{
          startAdornment: <Box mr={1} fontSize='caption.fontSize' color='text.label'>
            {I18n.t('address.coordinates.lat')}
          </Box>
        }}
        allowNull
        precision={7}
        minValue={-90}
        maxValue={90}
      />
    </Grid>
    <Grid item xs={3}>
      <NumberField
        name='meeting_point_coordinates.lng'
        label=' '
        type='float'
        InputProps={{
          startAdornment: <Box mr={1} fontSize='caption.fontSize' color='text.label'>
            {I18n.t('address.coordinates.lng')}
          </Box>
        }}
        allowNull
        precision={7}
        minValue={-180}
        maxValue={180}
      />
    </Grid>
  </Grid >
)

const Row3 = ({ poi_coordinates, meeting_point_coordinates, onMapClick, isReadOnly }) => (
  <Grid container xs={12} justifyContent={'center'}>
    <Grid item xs>
      <MapFactory.MapContainer
        mapId='supervisionScreen'
        mapStyle={{ height: '580px' }}
        eventHandlers={{ click: onMapClick }}
      >
        <MapFactory.SuperLayer fitToBounds followBounds={!!isReadOnly} boundOptions={{ animate: true }}>
          <MapFactory.Marker position={poi_coordinates} styles={{ icon: 'location_city' }} />
          <MapFactory.Marker position={meeting_point_coordinates} styles={{ icon: 'get_app' }} />
        </MapFactory.SuperLayer>
      </MapFactory.MapContainer>
    </Grid>
  </Grid>
)

const AddressSection = ({ onAddressResolve, mapProps }) => (
  <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('geo.area')}</Typography>
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 3, boxShadow: 6, borderRadius: 1 }}>
        <Grid container gap={2}>
          <Row1 onAddressResolve={onAddressResolve} />
          <Row2 />
          <Row3 {...mapProps} />
        </Grid>
      </Box>
    </StepContent>
  </>
)

export default React.memo(AddressSection)
