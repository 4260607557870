
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name_trkey: _.get(record, 'name_trkey'),
        icon: _.get(record, 'icon'),
        display_index: _.get(record, 'display_index'),
        active: _.get(record, 'active'),
        default_required_info: _.get(record, 'default_required_info'),
        reservationinfo: {
          name_trkey: _.get(record, 'reservationinfo.name_trkey'),
          origin_trkey: _.get(record, 'reservationinfo.origin_trkey'),
          arrival_trkey: _.get(record, 'reservationinfo.arrival_trkey'),
          scope: _.get(record, 'reservationinfo.scope')
        }
      }
    }
  }

  static getEmptyFormHandler(commercialpackage_id) {
    return function getEmptyFormValues() {
      return {
        active: false,
        default_required_info: false
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name_trkey.fr'),
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      return {
        name_trkey: _.get(values, 'name_trkey'),
        icon: _.get(values, 'icon'),
        display_index: _.get(values, 'display_index'),
        active: _.get(values, 'active'),
        default_required_info: _.get(values, 'default_required_info'),
        reservationinfo: _.has(values, 'reservationinfo') ? {
          name_trkey: _.get(values, 'reservationinfo.name_trkey'),
          origin_trkey: _.get(values, 'reservationinfo.origin_trkey'),
          arrival_trkey: _.get(values, 'reservationinfo.arrival_trkey'),
          scope: _.get(values, 'reservationinfo.scope')
        } : undefined
      }
    }
  }
}

export default Callbacks
