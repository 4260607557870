import React, { useState } from 'react'
import { MapFactory } from '@front/squirtle'
import _ from 'lodash'
import Controls from './controls'
import { LocalProvider, hooks } from '@front/volcanion'
const DataMarker = ({ children, vehicle_id, ...props }) => {
  const [vehicle_coordinates] = hooks.useModel('vehicle', [vehicle_id], { single: true, populate: ['current_location'], mutator: 'current_location.coordinates' })
  if (!!vehicle_coordinates?.lat && !!vehicle_coordinates?.lng) {
    console.log("🚀 ~ file: index.js:35 ~ DataMarker ~ vehicle_coordinates:", vehicle_coordinates?.lat, parseFloat(vehicle_coordinates?.lat), parseFloat(vehicle_coordinates?.lng))
    return (
      <MapFactory.Marker position={vehicle_coordinates} interactive {...props} >
        {children}
      </MapFactory.Marker>
    )
  }
  return null
}

const VehicleLayer = ({ ids, config, styles, ...rest }) => {
  const [vehicles] = hooks.useModel('vehicle', ids, config)
  return (
    <MapFactory.SuperLayer styles={styles} {...rest}>
      {_.map(vehicles, (vehicle) => <DataMarker
        key={vehicle?.vehicle_id}
        vehicle_id={vehicle?.vehicle_id}
        styles={styles}
        autoPanOnFocus={false}
      />)}
    </MapFactory.SuperLayer>
  )
}

const Playground = (props) => {
  const [vehicles] = hooks.useModelSearch('vehicle', 'get', { initial_filter: { status: ['available', 'in_use'] }, watch: true, watch_type: 'result', default_limit: -1, enableCount: true })
  console.log("🚀 ~ file: index.js:62 ~ Playground ~ vehicles:", vehicles)
  const [enabledLayers, setEnabledLayers] = useState([])
  return (
    <div>
      <LocalProvider context={{ enabledLayers, setEnabledLayers }}>
        <MapFactory.MapContainer
          mapStyle={{ height: window.innerHeight, top: 10 }}
          mapId='playground'
        >
          <MapFactory.SuperLayer>

            <MapFactory.LayersControl>
              <MapFactory.ControlBox><Controls /></MapFactory.ControlBox>
              <VehicleLayer
                filter={{ status: ['available'] }}
                control={{
                  name: 'available',
                  group: 'vehicle',
                  defaultEnabled: true
                }}
                styles={{
                  color: 'green',
                  className: 'l-icon-material',
                  icon: 'drive_eta',
                  markerColor: 'black',
                  circleColor: 'black',
                  circleOutlineColor: 'yellow',
                  circleOutlineWidth: '1',
                  iconColor: 'green',
                  fontSize: 8,
                  outlineColor: 'yellow',
                  outlineWidth: '1',
                  iconSize: 64
                }}
              />
              <VehicleLayer
                filter={{ status: ['in_use'] }}
                control={{
                  name: 'in_use',
                  group: 'vehicle',
                  defaultEnabled: true
                }}
                styles={{
                  color: 'orange',
                  className: 'l-icon-material',
                  icon: 'drive_eta',
                  markerColor: 'black',
                  circleColor: 'black',
                  circleOutlineColor: 'yellow',
                  circleOutlineWidth: '1',
                  iconColor: 'orange',
                  fontSize: 8,
                  outlineColor: 'yellow',
                  outlineWidth: '1',
                  iconSize: 64
                }}
              />
              <VehicleLayer
                filter={{ status: ['unavailable'] }}
                control={{
                  name: 'en_direction',
                  group: 'vehicle',
                  defaultEnabled: true
                }}
                styles={{
                  color: 'red',
                  className: 'l-icon-material',
                  icon: 'drive_eta',
                  markerColor: 'black',
                  circleColor: 'black',
                  circleOutlineColor: 'yellow',
                  circleOutlineWidth: '1',
                  iconColor: 'red',
                  fontSize: 8,
                  outlineColor: 'yellow',
                  outlineWidth: '1',
                  iconSize: 64
                }}
              />


            </MapFactory.LayersControl>
          </MapFactory.SuperLayer>

        </MapFactory.MapContainer>

      </LocalProvider>
    </div>
  )
}

export default React.memo(Playground)
