import React, { useState } from 'react'

const withContainer = Component => props => {
  const [isWaiting, setIsWaiting] = useState(true)
  const mergedProps = {
    isWaiting,
    setIsWaiting
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
