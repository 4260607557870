const exportCompanycontractNoShow = (values, state) => {
  const commercial_packages = _.get(values, 'commercial_packages')
  const mode = _.get(values, 'mode')
  const commercial_formula = _.get(values, 'commercial_formula')
  const companycontract_id = _.get(state, 'companycontract_id')
  const companycontract_noshows = _.get(state, 'companycontract_noshows')
  const record_id = _.get(state, 'record_id')

  const exist_elems = _.map(
    _.filter(companycontract_noshows, ccn => _.includes(commercial_packages, ccn.commercial_package)),
    elem => ({
      companycontractnoshow_id: elem.companycontractnoshow_id,
      commercial_package: elem.commercial_package,
      commercial_formula,
      company_contract: companycontract_id,
      mode,
      noshow: record_id
    }))

  const new_elems = _.map(
    _.filter(commercial_packages, cp => !_.includes(_.map(companycontract_noshows, 'commercial_package'), cp)),
    commercial_package_id => ({
      commercial_package: commercial_package_id,
      commercial_formula,
      company_contract: companycontract_id,
      mode,
      noshow: record_id
    })
  )

  return _.compact(_.flatten([exist_elems, new_elems]))
}

const exportCommercialPackageNoshow = (values, state) => {
  const commercial_packages = _.get(values, 'commercial_packages')
  const mode = _.get(values, 'mode')
  const commercial_formula = _.get(values, 'commercial_formula')
  const commercialpackage_noshows = _.get(state, 'commercialpackage_noshows')
  const record_id = _.get(state, 'record_id')


  const exist_elems = _.map(
    _.filter(commercialpackage_noshows, cpn => _.includes(commercial_packages, cpn.commercial_package)),
    elem => ({
      commercialpackage_noshow_id: elem.commercialpackage_noshow_id,
      commercial_package: elem.commercial_package,
      commercial_formula,
      mode,
      noshow: record_id
    }))

  const new_elems = _.map(_.filter(commercial_packages, cp => !_.includes(_.map(commercialpackage_noshows, 'commercial_package'), cp)),
    commercial_package_id => ({
      commercial_package: commercial_package_id,
      commercial_formula,
      mode,
      noshow: record_id
    }))

  return _.compact(_.flatten([exist_elems, new_elems]))
}

const Aux = {
  exportCompanycontractNoShow,
  exportCommercialPackageNoshow
}

export default Aux
