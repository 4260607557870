class Callbacks {
  static filterToFormHandler(company_id) {
    return function filterToForm(filter) {
      return {
        company_id,
        ...filter
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return values
    }
  }
}

export default Callbacks
