import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid } from '@mui/material'

import { TextField, ModelMenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('specificparameter.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} justifyContent='center' spacing={1}>
    <Grid item xs>
      <TextField name={'specific.name'} label={I18n.t('name.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'commercialpackage'}
        label={I18n.t('commercialpackage.label', { count: 1 })}
        model_name={'commercialpackage'}
        labelKeys={['name_translated']}
        loadOnMount
        config={{ sortKeys: ['display_index'], sortOrders: ['asc'] }}
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container rowSpacing={3}>
    <Title />
    <Row1 />
  </Grid>

export default React.memo(SearchForm)
