
import React from 'react'

const withContainer = Component => (props) => {

  const translations = [
    { name: 'name_trkey', labelKey: 'name.label.one', xs: 5, required: true }
  ]

  const icons = [
    'pin_drop',
    'flight',
    'train',
    'flag',
    'airport_shuttle',
    'local_bar',
    'account_balance',
    'apartment'
  ]

  const mergedProps = {
    translations,
    icons
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
