import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import OrderDetails from '@abra/frames/Order'

const OrderDetailsPage = props => {
  const { order_id } = hooks.useParams()
  return (
    <Box sx={{ pr: 6, pt: 2 }}>
      <OrderDetails order_id={order_id} form_name='order_form_details' disableHeader={!order_id} />
    </Box>
  )
}
export default React.memo(OrderDetailsPage)
