
import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => (props) => {

  const setFormValues = hooks.useFormChange()
  const [type] = hooks.useFieldValues(['type'])
  const [poitype] = hooks.useModel('poitype', [type], { single: true })
  const required_additional_info_value = useMemo(() => _.get(poitype, 'default_required_info'), [_.get(poitype, 'default_required_info')])
  const onTypeChange = useCallback(() => setFormValues([{ field: 'required_additional_info', value: required_additional_info_value }]), [required_additional_info_value, setFormValues])
  const display_poi_client_id = _.includes(['collecto', 'stib'], type)

  const mergedProps = {
    onTypeChange,
    display_poi_client_id
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
