import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { pricerule_id, } = hooks.useParams()
  const search_params = hooks.useSearchParams()
  const companycontract_id = _.head(_.get(search_params, 'companycontract_id'))
  const commercialpackage_id = _.head(_.get(search_params, 'commercialpackage_id'))

  const formProps = {
    name: 'pricerule_form',
    model_name: 'pricerule',
    record_id: pricerule_id,
    related_states: [],
    related_searches: [],
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(companycontract_id, commercialpackage_id), [companycontract_id, commercialpackage_id]),
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'pricing',
      'companycontract_pricerules',
      'commercialpackage_pricerules',
      'commercial_packages'
    ],
    initialState: { isReadOnly: !!pricerule_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    submitChangesOnly: false,
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
