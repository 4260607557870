import React, { useMemo } from 'react'

const withContainer = Component => props => {

  const translations = useMemo(() => [
    { name: 'name_trkey', labelKey: 'name.label.one', xs: 2, required: true },
    { name: 'meeting_point_trkey', labelKey: 'address.meeting_point', xs: 3, required: true },
    { name: 'driver_instruction_trkey', labelKey: 'address.meeting_point_driver', xs: 4, required: true }
  ], [])

  const mergedProps = {
    translations
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
