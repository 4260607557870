import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'

import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('type.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('order.id.alter')} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('date.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('commercialpackage.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('commercial_formula.label.short_alter')} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('user.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('address.label', { count: 2 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('transport.estimate_duration')} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('contact.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('driver.label.short')} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('status.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('channel.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('payment.label', { count: 1 })} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('eta')} />
  </TableCell>
  <TableCell >
    <TextHeader textAlign='center' label={I18n.t('price.total')} />
  </TableCell>
</>

export default React.memo(Header)
