
import React from 'react'

import { hooks } from '@front/volcanion/'

const withContainer = Component => ({ isTaxicounter, ...props }) => {
  const applicable_load = hooks.useFieldValue('applicable_load')
  const [companycontract_id] = hooks.useFormState('companycontract_id')
  const [companycontract] = hooks.useModel('companycontract', [companycontract_id], { single: true })
  const authorized_formulas = companycontract?.formula

  const mergedProps = {
    authorized_formulas,
    companycontract_id,
    applicable_load,
    isTaxicounter
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
