import { TimeUtils } from '@front/volcanion/utils'
import moment from 'moment'

class Callbacks {
  static handleOnFilterChange(reset, reload, onSubmit) {
    return async function onFilterChange(filter) {
      await reset()
      await reload(filter)
      await onSubmit()
    }
  }
  static handleActiveFilter(onFilterChange) {
    return async function activeFilter() {
      return onFilterChange({
        status: ['standby', 'attributed'],
        or: [
          { assigned_transport: { status: ['started', 'at_src', 'on_board', 'at_dst', 'off_board'] } },
          { assigned_transport: null }
        ]
      })
    }
  }
  static handleReservationFilter(onFilterChange) {
    return async function activeFilter() {
      return onFilterChange({
        status: ['created', 'standby', 'attributed'],
        schedule_type: ['planned']
      })
    }
  }
  static handleNotLoadedFilter(onFilterChange) {
    return async function notLoadedFilter() {
      return onFilterChange({
        status: ['canceled'],
        reason: ['not_loaded']
      })
    }
  }
  static handleTodayFilter(onFilterChange) {
    return async function todayFilter() {
      const now = moment()
      return onFilterChange({
        requestedAt_start: TimeUtils.formatDateFrontToBack(now.startOf('day')),
        requestedAt_end: TimeUtils.formatDateFrontToBack(now.endOf('day')),
      })
    }
  }
  static handleTodayPlusOneFilter(onFilterChange) {
    return async function todayPlusOneFilter() {
      const now = moment()
      return onFilterChange({
        requestedAt_start: TimeUtils.formatDateFrontToBack(now.startOf('day')),
        requestedAt_end: TimeUtils.formatDateFrontToBack(now.endOf('day').add(1, 'day')),
      })
    }
  }
  static handleTodayPlusTwoFilter(onFilterChange) {
    return async function todayPlusTwoFilter() {
      const now = moment()
      return onFilterChange({
        requestedAt_start: TimeUtils.formatDateFrontToBack(now.startOf('day')),
        requestedAt_end: TimeUtils.formatDateFrontToBack(now.endOf('day').add(2, 'day')),
      })
    }
  }
  static handleTodayCreatedFilter(onFilterChange) {
    return async function todayCreatedFilter() {
      const now = moment()
      return onFilterChange({
        requestedAt_start: TimeUtils.formatDateFrontToBack(now.startOf('day')),
        requestedAt_end: TimeUtils.formatDateFrontToBack(now.endOf('day')),
        status: ['created', 'standby'],
      })
    }
  }
  static handleTodayCanceledFilter(onFilterChange) {
    return async function todayCanceledFilter() {
      const now = moment()
      return onFilterChange({
        requestedAt_start: TimeUtils.formatDateFrontToBack(now.startOf('day')),
        requestedAt_end: TimeUtils.formatDateFrontToBack(now.endOf('day')),
        status: ['canceled'],
      })
    }
  }
  static handleGreaterThanThreeFilter(onFilterChange) {
    return async function greaterThanThreeFilter() {
      const now = moment()
      return onFilterChange({
        requestedAt_start: TimeUtils.formatDateFrontToBack(now.startOf('day').add(3, 'day')),
      })
    }
  }
}

export default Callbacks
