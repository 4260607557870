import React from 'react'

import { Badge, Grid, Typography } from '@mui/material'
import { I18n, hooks } from '@front/volcanion'

import List from './List'
import Driver from './Driver'


const MultipleDispatch = (props) => {
  const [order_ids] = hooks.useFormState('order_ids')
  return (
    <Grid container columnSpacing={4} rowSpacing={2}>
      <Grid item xs={12}>
        <Badge badgeContent={order_ids?.length} color="primary" sx={{ m: 0 }}>
          <Typography variant='h5' color='primary' textTransform='uppercase'>
            {I18n.t('dispatch.group.title')}
          </Typography>
        </Badge>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography fontSize={16} color='primary' fontWeight={600}>
          {I18n.t('dispatch.group.text')}
        </Typography>
      </Grid>
      <Grid item xs={8} sx={{ maxHeight: 780, overflowY: 'scroll' }}>
        <List />
      </Grid>
      <Grid item xs={4}>
        <Driver />
      </Grid>
    </Grid>
  )
}

export default React.memo(MultipleDispatch) 
