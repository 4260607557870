import React from 'react'

import { I18n } from '@front/volcanion/'
import { Box, StepLabel, StepContent, Typography } from '@mui/material'

import TranslationPanel from '@abra/panels/Translation'

const TranslationSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('translation', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 3, boxShadow: 6, borderRadius: 1 }}>
      <TranslationPanel {...props} />
    </Box>
  </StepContent>
</>

export default React.memo(TranslationSection)
