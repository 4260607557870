const exportCompanycontractPricerules = (values, state) => {
    const commercial_packages = _.get(values, 'commercial_packages')
    const mode = _.get(values, 'mode')
    const companycontract_id = _.get(state, 'companycontract_id')
    const companycontract_pricerules = _.get(state, 'companycontract_pricerules')
    const record_id = _.get(state, 'record_id')

    const exist_elems = _.map(
        _.filter(companycontract_pricerules, ccp => _.includes(commercial_packages, ccp.commercialpackage)),
        elem => ({
            id: elem.id,
            pricerule: record_id,
            commercialpackage: elem.commercialpackage,
            companycontract: companycontract_id,
            mode,
        }))

    const new_elems = _.map(_.filter(commercial_packages, cp => !_.includes(_.map(companycontract_pricerules, 'commercialpackage'), cp)),
        commercialpackage_id => ({
            commercialpackage: commercialpackage_id,
            companycontract: companycontract_id,
            pricerule: record_id,
            mode,
        }))

    return _.compact(_.flatten([exist_elems, new_elems]))
}

const exportCommercialPackagePricerules = (values, state) => {
    const commercial_packages = _.get(values, 'commercial_packages')
    const mode = _.get(values, 'mode')
    const commercialpackage_pricerules = _.get(state, 'commercialpackage_pricerules')
    const record_id = _.get(state, 'record_id')

    const exist_elems = _.map(
        _.filter(commercialpackage_pricerules, cpp => _.includes(commercial_packages, cpp.commercialpackage)),
        elem => ({
            id: elem.id,
            pricerule: record_id,
            commercialpackage: elem.commercialpackage,
            mode,
        }))

    const new_elems = _.map(_.filter(commercial_packages, cp => !_.includes(_.map(commercialpackage_pricerules, 'commercialpackage'), cp)),
        commercialpackage_id => ({
            commercialpackage: commercialpackage_id,
            pricerule: record_id,
            mode,
        }))

    return _.compact(_.flatten([exist_elems, new_elems]))
}

const Aux = {
    exportCompanycontractPricerules,
    exportCommercialPackagePricerules,
}

export default Aux
