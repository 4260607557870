import React from 'react'
import { hooks } from '@front/volcanion'
import { TimeUtils, GeoUtils } from '@front/volcanion/utils'

import moment from 'moment'

import { OrderLink } from '@abra/elements'
import { TableCell, Box, Icon } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => {
  const contact_name = !!row?.service ? row?.service?.contract?.company?.name : row?.do?.customerinfo?.name
  const requestedAt = moment.utc(TimeUtils.getDetailsMomentFront(row?.requestedAt, row?.source)).local().format('DD/MM/YYYY HH:mm')
  const { ORDER_LIST_SOURCE_FORMAT, ORDER_LIST_DESTINATION_FORMAT } = hooks.useRelayConstants(['ORDER_LIST_SOURCE_FORMAT', 'ORDER_LIST_DESTINATION_FORMAT',])
  return (
    <>
      <TableCell size='small' padding='none'>
        <OrderLink record_id={row?.order_id} fontSize={13} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <Box display='flex' justifyContent='center'>
          <Icon color='primary' fontSize={'small'} mr={2}>
            {row?.schedule_type === 'planned' ? 'calendar_month' : 'download'}
          </Icon>
          <TextCell fontSize={13} value={requestedAt} />
        </Box>
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={contact_name} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={_.get(row, 'loads.0.src_contact')} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={GeoUtils.getSpecificFormat(row?.source, ORDER_LIST_SOURCE_FORMAT || 'friendly')} />
        <TextCell fontSize={13} value={GeoUtils.getSpecificFormat(row?.destination, ORDER_LIST_DESTINATION_FORMAT || 'friendly')} />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
