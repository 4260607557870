import React from 'react'
import { Route, Routes } from '@front/squirtle'

import PoiType from './Details'
import PoiTypeList from './List'

const PoiTypePage = props =>
  <Routes>
    <Route index element={<PoiTypeList />} />
    <Route path='/create' element={<PoiType />} />
    <Route path='/:poitype_id' element={<PoiType />} />
  </Routes>

export default React.memo(PoiTypePage)
