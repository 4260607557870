import _ from "lodash"
import Aux from "./Aux"

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const isGeneric = !!record?.generic
      return {
        name: _.get(record, 'name'),
        active: _.get(record, 'active'),
        applicable_load: _.get(record, 'applicable_load'),
        start_zone: _.get(record, 'start_zone'),
        end_zone: _.get(record, 'end_zone'),
        priority: _.get(record, 'priority'),
        distance_algo: _.get(record, 'distance_algo'),
        schedule: _.get(record, 'schedule'),
        two_way: _.get(record, 'two_way'),
        individual_pricing: _.get(record, 'individual_pricing'),
        distance_strategy: _.get(record, 'distance_strategy'),
        accuracy: _.get(record, 'accuracy'),
        rateparameter: _.get(record, 'pricing'),
        mode: !!isGeneric
          ? _.get(_.head(_.get(record, 'commercialpackage_pricerules')), 'mode')
          : _.get(_.head(_.get(record, 'companycontract_pricerules')), 'mode'),
        commercial_packages: !!isGeneric
          ? _.map(_.get(record, 'commercialpackage_pricerules'), 'commercialpackage')
          : _.map(_.get(record, 'companycontract_pricerules'), 'commercialpackage')
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        applicable_load: 'user',
        individual_pricing: false,
        two_way: false,
        distance_strategy: 'basic',
        active: true
      }
    }
  }

  static getEmptyStateHandler(companycontract_id, commercial_package_id) {
    return function getEmptyState() {
      return {
        companycontract_id,
        commercial_package_id,
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      const generic = !state?.companycontract_id
      return {
        generic,
        name: _.get(values, 'name'),
        active: _.get(values, 'active'),
        applicable_load: _.get(values, 'applicable_load'),
        distance_algo: _.get(values, 'distance_algo'),
        start_zone: _.get(values, 'start_zone'),
        end_zone: _.get(values, 'end_zone'),
        two_way: _.get(values, 'two_way'),
        priority: _.get(values, 'priority'),
        schedule: _.get(values, 'schedule'),
        taxicounter_customer: _.get(values, 'taxicounter_customer'),
        taxicounter_driver: _.get(values, 'taxicounter_driver'),
        individual_pricing: _.get(values, 'individual_pricing'),
        pricing: _.get(values, 'rateparameter.rateparameter_id'),
        distance_strategy: _.get(values, 'distance_strategy'),
        accuracy: _.get(values, 'accuracy'),
        companycontract_pricerules: !generic
          ? Aux.exportCompanycontractPricerules(values, state)
          : undefined,
        commercialpackage_pricerules: !!generic
          ? Aux.exportCommercialPackagePricerules(values, state)
          : undefined
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name'),
        companycontract_id: _.get(_.head(record?.companycontract_pricerules), 'companycontract'),
        companycontract_pricerules: record?.companycontract_pricerules,
        commercialpackage_id: _.get(_.head(record?.commercialpackage_pricerules), 'commercialpackage'),
        commercialpackage_pricerules: record?.commercialpackage_pricerules,
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }

  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
}

export default Callbacks
