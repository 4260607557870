import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import Callbacks from './callbacks'

const PriceruleList = props => {
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])

  return (
    <Grid container justifyContent='center' alignItems='center' sx={{ bgcolor: 'background.primary' }}>
      <Grid item xs={11}>
        <Box sx={{ mt: 2 }}>
          <SearchFrame
            model_name={'pricerule'}
            populate={['commercial_packages', 'start_zone', 'end_zone']}
            createButtonProps={{
              label: I18n.t('pricerule.add', { count: 1 })
            }}
          >
            <SearchForm
              submitOnMount
              formToFilter={formToFilter}
              filterToForm={filterToForm}
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Box>
      </Grid>
    </Grid>
  )
}


export default React.memo(PriceruleList)
