import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [poi_coordinates, meeting_point_coordinates] = hooks.useFieldValues(['coordinates', 'meeting_point_coordinates'])
  const [isReadOnly] = hooks.useFormState('isReadOnly')

  const setFormValues = hooks.useFormChange()
  const onAddressResolve = useCallback(Callbacks.handleOnAddressResolve(setFormValues), [setFormValues])
  const onMapClick = useCallback(Callbacks.handleOnMapClick(setFormValues, isReadOnly), [setFormValues, isReadOnly])

  const mapProps = {
    onMapClick,
    poi_coordinates,
    meeting_point_coordinates,
    isReadOnly
  }

  const mergedProps = {
    onAddressResolve,
    mapProps
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
