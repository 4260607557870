import React, { useCallback } from 'react'
import { FormTable, TableHeader, TableRow } from "@front/squirtle"
import Header from "./Header"
import Row from "./Row"
import { hooks } from '@front/volcanion'

const List = (props) => {
  const [order_ids] = hooks.useFormState('order_ids')
  const order_watcher = _.flatten([order_ids]).join(',')
  return (
    <FormTable
      model_name={'order'}
      loadOnMount={false}
      config={{
        populate: ['service.contract.company', 'do.customerinfo', 'loads', 'source.poi', 'destination.poi',],
        sort: ['requestedAt DESC'],
        initial_filter: {},
        forced_filter: {
          order_id: order_ids
        },
        validate_filter: useCallback(() => !_.isEmpty(order_ids), [order_watcher]),
        watchers: [order_watcher]
      }}
      tableProps={{
        enablePagination: false,
        onRowClick: null,
      }}
    >
      <TableHeader><Header /></TableHeader>
      <TableRow><Row /></TableRow>
    </FormTable>
  )
}

export default List

