import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, SingleOptionSelectorField } from '@front/squirtle/'

const EndAdornmentComponent = ({ labelKey = 'fiat.eur.sign', ...props }) =>
  <Typography sx={{ fontSize: 'subtitle.fontSize', color: 'text.label' }}> {I18n.t(labelKey)} </Typography>

const Row1 = props =>
  <Grid container item xs={8} spacing={1}>
    <Grid item xs>
      <TextField
        name={'priority'}
        label={I18n.t('priority.label', { count: 1 })}
        type={'number'}
        emptyValue={'0'}
        InputProps={{
          inputProps: { style: { textAlign: 'center' } }
        }}
        normalize={value => _.min([_.max([value, 1]), 99])}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'driver_manual_allowed'}
        optionLabel={I18n.t('manual_selection')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs={4}>
      <SingleOptionSelectorField
        name={'apply_waiting_time'}
        optionLabel={I18n.t('quotation.add_waiting_billing')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'fixed_rate'}
        label={I18n.t('user.fixed_rate')}
        type={'number'}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'max_rate'}
        label={I18n.t('ceiling.counter')}
        type={'number'}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
      />
    </Grid>
  </Grid >

const NoShowSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('quotation.no_show')} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(NoShowSection)
