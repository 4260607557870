import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Pricerule from './Details'
import PriceruleList from './List'

const PricerulePage = props =>
  <Routes>
    <Route index element={<PriceruleList />} />
    <Route path='/create' element={<Pricerule />} />
    <Route path='/:pricerule_id' element={<Pricerule />} />
  </Routes>

export default React.memo(PricerulePage)
