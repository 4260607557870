import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'

import { TextField, SingleOptionSelectorField, ModelMenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('poi.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={9} justifyContent="center" spacing={1}>
    <Grid item xs>
      <ModelMenuSelectorField
        name='type'
        label={I18n.t('type.label', { count: 1 })}
        model_name='poitype'
        labelKeys={['name_translated']}
        searchMode={'search'}
        loadOnMount
        allowNone={false}
        config={{ force_filter: { active: true } }}
      />
    </Grid>
    <Grid item xs>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} />
    </Grid>
    <Grid item xs={1}>
      <SingleOptionSelectorField name='active' label={I18n.t('active')} />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container justifyContent={'center'} rowSpacing={3}>
          <Title />
          <Row1 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
