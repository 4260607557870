
import React from 'react'

import { Box } from '@mui/material'

import OrderForm from '@abra/frames/Order'
import WaitComponent from './waitComponent'

const OrderPage = props => {
  const {
    handleClickHistory,
    handleClickCreateOrder,
    handleClickReturnHome,
    ...rest
  } = props

  const form_name = 'order_form'
  return <Box>
    <WaitComponent {...rest} form_name={form_name} />
    <Box sx={{ pr: 6 }}>
      <OrderForm {...rest} form_name={form_name} disableHeader={true} />
    </Box>
  </Box>
}

export default React.memo(OrderPage)
