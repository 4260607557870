import React, { useCallback } from 'react'
import Callbacks from './callbacks'
import { hooks } from '@front/volcanion'

const withContainer = Component => (props) => {
  const [dispatchJobs] = hooks.useModelFunction('job', 'dispatchMultiple')
  const submitMultipleDispatch = useCallback(Callbacks.handleSubmitMultipleDispatch(dispatchJobs), [dispatchJobs])
  const mergedProps = {
    submitMultipleDispatch,
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
