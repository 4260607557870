import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, ModelMenuSelectorField } from '@front/squirtle/'

import CPUtils from '@front/squirtle/utils/CPUtils'

const Row1 = ({ authorized_formulas }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={3}>
      <ModelMenuSelectorField
        name='mode'
        model_name='ridemode'
        label={I18n.t('ride.label', { count: 1 })}
        labelKeys={['name_translated']}
        searchMode='search'
        config={{
          forced_filter: { enabled: true },
          sort: ['display_index ASC']
        }}
        loadOnMount
        selectFirst
        noneFirst
        noneLabel={I18n.t('all')}
      />
    </Grid>
    <Grid item xs={3}>
      <ModelMenuSelectorField
        name='commercial_formula'
        model_name='commercialformula'
        label={I18n.t('commercialformula.label', { count: 1 })}
        labelKeys={['name']}
        searchMode='search'
        loadOnMount
        selectFirst
        noneFirst
        noneLabel={I18n.t('all')}
      />
    </Grid>
    <Grid item xs={12}>
      <ModelMenuSelectorField
        name='commercial_packages'
        label={I18n.t('commercialpackage.label', { count: 2 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        config={{
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp }),
          forced_filter: { authorized_formulas },
          watchers: [authorized_formulas],
        }}
        searchMode={'search'}
        loadOnMount
        multiple
        clearOnEmpty
        required
      />
    </Grid>
  </Grid>

const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
