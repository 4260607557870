import React from 'react'
import { Route, Routes } from '@front/squirtle'

import NoShow from './Details'
import NoShowList from './List'

const NoShowPage = props =>
  <Routes>
    <Route index element={<NoShowList />} />
    <Route path='/create' element={<NoShow />} />
    <Route path='/:noshow_id' element={<NoShow />} />
  </Routes>

export default React.memo(NoShowPage)
