import React, { useCallback } from 'react'
import { MapHooks, OptionSelectorComponent, SingleOptionSelectorComponent } from '@front/squirtle'
import { Paper, Box, Checkbox, Typography } from '@mui/material'
import _ from 'lodash'

const LayersControl = ({ children, hideUngrouped }) => {
  const context = MapHooks.useLeafletContext()

  const { layersControl, map, clusterContainer } = context
  const parentContainer = clusterContainer || map

  const [layers_with_groups, layers_without_groups] = _.partition(layersControl.getLayers(), (layer) => !!layer.getControlGroup())
  const grouped_layers = _.groupBy(layers_with_groups, (layer) => layer.getControlGroup())
  const nonGroupOptions = _.map(layers_without_groups, (layer) => ({ label: layer.getControlName(), value: layer.getLayerId() }))
  const onChange = useCallback((new_layers) => {
    const layers_to_add = _.filter(layersControl.getLayers(), (layer) => _.includes(new_layers, layer.getLayerId()) && !parentContainer?.hasLayer(layer))
    const layers_to_remove = _.filter(layersControl.getLayers(), (layer) => !_.includes(new_layers, layer.getLayerId()) && parentContainer?.hasLayer(layer))
    _.map(layers_to_add, (layer_to_add) => layer_to_add?.addTo(parentContainer))
    _.map(layers_to_remove, (layer_to_remove) => layer_to_remove.remove(parentContainer))
  }, [layersControl, parentContainer])
  const handleChange = useCallback((layer, isChecked) => !!isChecked ? layer.addTo(parentContainer) : layer.removeFrom(parentContainer), [parentContainer])

  return (
    <Paper
      style={{
        width: 250,
        backgroundColor: 'white',
        borderRadius: 25,
        padding: 10
      }}
    >
      {_.map(grouped_layers, (grouped_layer, group_name) => {
        const group_active_layers = _.filter(grouped_layer, layer => layersControl.isLayerEnabled(layer))
        return (
          <Box key={group_name}>
            {!!group_name && (
              <SingleOptionSelectorComponent
                optionLabel={group_name}
                value={!_.isEmpty(group_active_layers)}
                onChange={(new_value) => !!new_value ? _.map(grouped_layer, (layer_to_add) => layer_to_add.layer?.addTo(map)) : _.map(grouped_layer, (layer_to_remove) => layer_to_remove.layer?.remove())}
              />
            )}
            {_.map(grouped_layer, (layer) => (
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox
                  onChange={(e) => handleChange(layer, e.target.checked)}
                  checked={layersControl.isLayerEnabled(layer)}
                />
                <Box style={{ backgroundColor: layer.getStyle('color'), width: '20px', height: '20px', marginRight: '5px' }}></Box>
                <Typography>{layer.getControlName()}</Typography>
              </Box>
            ))}
          </Box>
        )
      })}
      {!hideUngrouped && <OptionSelectorComponent
        options={nonGroupOptions}
        multiple
        onChange={onChange}
        value={layersControl.getEnabledLayerIds()}
      />}
      {children}
    </Paper>
  );
}


export default React.memo(LayersControl)
