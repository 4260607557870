import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell><TextCell value={_.get(row, 'name_translated')} /></TableCell>
  <TableCell><TextCell value={_.get(row, 'poi_client_id')} /></TableCell>
  <TableCell><TextCell value={_.get(row, 'type.name_translated')} /></TableCell>
</>

export default React.memo(Row)
