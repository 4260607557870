import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { noshow_id } = hooks.useParams()
  const search_params = hooks.useSearchParams()
  const companycontract_id = _.head(_.get(search_params, 'companycontract_id'))
  const commercial_package_id = _.head(_.get(search_params, 'commercial_package_id'))

  const formProps = {
    name: 'noshow_form',
    model_name: 'noshow',
    record_id: noshow_id,
    related_states: [],
    related_searches: [],
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(companycontract_id, commercial_package_id), [companycontract_id, commercial_package_id]),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'companycontract_noshows',
      'commercialpackage_noshows'
    ],
    initialState: { isReadOnly: !!noshow_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    submitChangesOnly: false,
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
