import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { Button, Box } from '@mui/material'
import Callbacks from './callbacks'

const ButtonGlobalAction = ({ model_name }) => {
  const reload = hooks.useGlobalFormFunction(`${model_name}_search`, 'reload')
  const onSubmit = hooks.useGlobalFormFunction(`${model_name}_search`, 'requestSubmit')
  const reset = hooks.useGlobalFormFunction(`${model_name}_search`, 'reset')

  const {
    FILTER_ACTIVE,
    FILTER_RESERVATION,
    FILTER_NOT_LOADED,
    FILTER_TODAY,
    FILTER_TODAY_PLUS_ONE,
    FILTER_TODAY_PLUS_TWO,
    FILTER_TODAY_CREATED,
    FILTER_TODAY_CANCELED,
    FILTER_GREATER_THAN_THREE,
  } = hooks.useRelayFeatures([
    'FILTER_ACTIVE',
    'FILTER_RESERVATION',
    'FILTER_NOT_LOADED',
    'FILTER_TODAY',
    'FILTER_TODAY_PLUS_ONE',
    'FILTER_TODAY_PLUS_TWO',
    'FILTER_TODAY_CREATED',
    'FILTER_TODAY_CANCELED',
    'FILTER_GREATER_THAN_THREE'
  ])

  const onFilterChange = useCallback(Callbacks.handleOnFilterChange(reset, reload, onSubmit), [reset, reload, onSubmit])
  const activeFilter = useCallback(Callbacks.handleActiveFilter(onFilterChange), [onFilterChange])
  const reservationFilter = useCallback(Callbacks.handleReservationFilter(onFilterChange), [onFilterChange])
  const notLoadedFilter = useCallback(Callbacks.handleNotLoadedFilter(onFilterChange), [onFilterChange])
  const todayFilter = useCallback(Callbacks.handleTodayFilter(onFilterChange), [onFilterChange])
  const todayPlusOneFilter = useCallback(Callbacks.handleTodayPlusOneFilter(onFilterChange), [onFilterChange])
  const todayPlusTwoFilter = useCallback(Callbacks.handleTodayPlusTwoFilter(onFilterChange), [onFilterChange])
  const todayCreatedFilter = useCallback(Callbacks.handleTodayCreatedFilter(onFilterChange), [onFilterChange])
  const todayCanceledFilter = useCallback(Callbacks.handleTodayCanceledFilter(onFilterChange), [onFilterChange])
  const greaterThanThreeFilter = useCallback(Callbacks.handleGreaterThanThreeFilter(onFilterChange), [onFilterChange])

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {!!FILTER_ACTIVE && <Button variant={'contained'} size='small' onClick={activeFilter}>{I18n.t('order.filter.active')}</Button>}
      {!!FILTER_RESERVATION && <Button variant={'contained'} size='small' onClick={reservationFilter}>{I18n.t('order.filter.reservation')}</Button>}
      {!!FILTER_NOT_LOADED && <Button variant={'contained'} size='small' onClick={notLoadedFilter}>{I18n.t('order.filter.not_loaded')}</Button>}
      {!!FILTER_TODAY && <Button variant={'contained'} size='small' onClick={todayFilter}>{I18n.t('order.filter.today')}</Button>}
      {!!FILTER_TODAY_PLUS_ONE && <Button variant={'contained'} size='small' onClick={todayPlusOneFilter}>{I18n.t('order.filter.today_plus_one')}</Button>}
      {!!FILTER_TODAY_PLUS_TWO && <Button variant={'contained'} size='small' onClick={todayPlusTwoFilter}>{I18n.t('order.filter.today_plus_thow')}</Button>}
      {!!FILTER_TODAY_CREATED && <Button variant={'contained'} size='small' onClick={todayCreatedFilter}>{I18n.t('order.filter.today_created')}</Button>}
      {!!FILTER_TODAY_CANCELED && <Button variant={'contained'} size='small' onClick={todayCanceledFilter}>{I18n.t('order.filter.today_canceled')}</Button>}
      {!!FILTER_GREATER_THAN_THREE && <Button variant={'contained'} size='small' onClick={greaterThanThreeFilter}>{I18n.t('order.filter.greater_than_three')}</Button>}
    </Box>
  )
}

export default React.memo(ButtonGlobalAction)
