
import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'

import PrimarySection from './Section/Primary'
import SettingSection from './Section/Setting'

const Company = ({ companyContractLabel, company_id, companycontract_id }) => (
  <Grid container justifyContent='center'>
    <Grid container item xs={8} rowSpacing={1}>
      <Grid item xs>
        {!!companycontract_id ?
          <FormHeader
            subTitle={I18n.t('link.specificparameter')}
            onLinkClick={() => window.open(`/company/${company_id}/contract/${companycontract_id}`, '_blank')}
            linkText={companyContractLabel}
          /> : <FormHeader />
        }
      </Grid>
      <Grid item xs={12} >
        <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical">
          <Step><PrimarySection /></Step>
          <Step><SettingSection /></Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Grid>
)

export default React.memo(Company)
