const styles = theme => ({
  backdrop: {
    zIndex: 650,
    backgroundColor: '#000000B2',
    color: '#fff'
  },
  button: {
    zIndex: theme.zIndex.drawer + 2,
  }
})

export default styles
