import React, { useCallback, useMemo, useEffect } from 'react'
import { hooks } from '@front/volcanion'
import Callbacks from './callbacks'
import HookUtils from '@front/volcanion/utils/hooks'

const withContainer = Component => props => {
  const { isWaiting, setIsWaiting } = props
  const reset = hooks.useGlobalFormFunction('order_form', 'reset')
  const IVR_MAP = hooks.useRelayConfig('IVR_MAP')
  const navigate = hooks.useNavigate()
  const [markCallAsRead] = hooks.useModelFunction('inboundcall', 'delete')
  const [operator] = hooks.useActiveUser({ populate: ['info'] })
  const [inboundcall] = hooks.useModel('inboundcall', [], {
    allowAll: true,
    single: true,
    limit: 1
  })
  const isMounted = hooks.useGlobalFormStatus(props.form_name, 'isMounted')
  const setFormValues = hooks.useGlobalFormChange(props.form_name)
  const [, searchOrder] = hooks.useModelSearch('order', 'get')
  const [, setCallId] = hooks.useGlobalFormState(props.form_name, 'call_id')
  const [router_state, , clearRouterState] = hooks.useRouterState()
  const phone = _.get(router_state, 'phone')
  const getUsersListener = hooks.useModelListener('user', 'customer_search', 'search')
  const operatorName = _.get(operator, 'info.last_name')
  const inbound_call_id = _.get(inboundcall, 'inbound_call_id')
  const called_number = _.get(inboundcall, 'called_number')

  const caller_config = useMemo(() =>
    !_.find(IVR_MAP, ['number', called_number])
      ? _.find(IVR_MAP, ['number', null])
      : _.find(IVR_MAP, ['number', called_number])
    , [IVR_MAP, inbound_call_id, called_number])

  const inboundCallFunction = useCallback(Callbacks.inboundCallHandler(setFormValues, searchOrder, markCallAsRead, setIsWaiting, navigate, setCallId, clearRouterState),
    [setFormValues, searchOrder, markCallAsRead, navigate, setCallId, clearRouterState])

  const handleClickHistory = useCallback(() => {
    window.open(`/order?operator_name=${operatorName}`, '_blank')
  }, [operatorName])

  const handleClickReturnHome = useCallback(() => {
    reset()
    getUsersListener()?.clearSearch()
    !!isWaiting ? navigate('/') : setIsWaiting(true)
  }, [navigate, isWaiting, reset, getUsersListener])

  const handleClickCreateOrder = useCallback(() => {
    setIsWaiting(false)
  }, [setIsWaiting])

  const handleKeyDown = useCallback(keyInfo => {
    const { key } = keyInfo
    switch (key) {
      case 'Escape':
        return navigate('/')
      case 'Enter':
        return setIsWaiting(false)
    }
  }, [setIsWaiting, navigate])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [handleKeyDown])


  useEffect(() => {
    if (!!isMounted && !!isWaiting && (!!inboundcall || !!phone)) {
      inboundCallFunction(inboundcall, caller_config, phone)
    }
  }, [isMounted, HookUtils.getRecordsWatcher([inboundcall]), phone, isWaiting])

  const mergedProps = {
    handleClickHistory,
    handleClickCreateOrder,
    handleClickReturnHome,
    setIsWaiting,
    isWaiting,
    inboundcall,
    caller_config
  }


  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
