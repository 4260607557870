import React from 'react'

import { Box, Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from './Section/Primary'
import TranslationSection from './Section/Translation'
import AddressSection from './Section/Address'

const PoiTypeDetails = props => <>
  <Box className={'safearea'}>
    <Grid container justifyContent='center'>
      <Grid item xs={10}>
        <FormHeader />
        <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical">
          <Step> <PrimarySection {...props} /> </Step>
          <Step> <TranslationSection {...props} /> </Step>
          <Step> <AddressSection {...props} /> </Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(PoiTypeDetails)
