import GeoUtils from '@front/volcanion/utils/geo'
import { I18n } from '@front/volcanion'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name: _.get(record, 'name_trkey.fr'),
        type: _.get(record, 'type'),
        poi_client_id: _.get(record, 'poi_client_id'),
        active: _.get(record, 'active'),
        required_additional_info: _.get(record, 'required_additional_info'),
        name_trkey: _.get(record, 'name_trkey'),
        meeting_point_trkey: _.get(record, 'meeting_point_trkey'),
        driver_instruction_trkey: _.get(record, 'driver_instruction_trkey'),
        driver_instruction: _.get(record, 'driver_instruction_trkey.fr'),
        meeting_point_coordinates: _.get(record, 'meeting_point_coordinates'),
        coordinates: _.get(record, 'coordinates'),
        address: {
          formatted_address: GeoUtils.mergeAddress(record)
        }
      }
    }
  }

  static getEmptyFormHandler(commercialpackage_id) {
    return function getEmptyFormValues() {
      return {
        active: false
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name_trkey.fr'),
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      const current_lat = _.find(meta, ['field', 'meeting_point_coordinates.lat'])?.initial
      const current_lng = _.find(meta, ['field', 'meeting_point_coordinates.lng'])?.initial

      return _.merge({}, {
        name: _.get(values, 'name'),
        type: _.get(values, 'type'),
        poi_client_id: _.get(values, 'poi_client_id'),
        active: _.get(values, 'active'),
        required_additional_info: _.get(values, 'required_additional_info'),
        name_trkey: _.get(values, 'name_trkey'),
        meeting_point_trkey: _.get(values, 'meeting_point_trkey'),
        driver_instruction_trkey: _.get(values, 'driver_instruction_trkey'),
        driver_instruction: _.get(values, 'driver_instruction'),
        address: values?.address
      },
        (!!values?.meeting_point_coordinates?.lat || !!values?.meeting_point_coordinates?.lng) ? {
          meeting_point_coordinates: {
            lat: _.toNumber(values?.meeting_point_coordinates?.lat || current_lat),
            lng: _.toNumber(values?.meeting_point_coordinates?.lng || current_lng),
          }
        } : {}
      )
    }
  }

  static getFailedNotificationHandler() {
    return function getFailedNotification(err, data, extra, meta, state) {
      const code = err.getCode()
      if (code === 'UNPRECISE_ADDRESS') {
        return [I18n.t('error.address.unprecise'), { variant: 'error' }]
      }
    }
  }
}

export default Callbacks
