import React from 'react'

import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const PoiTypeList = props => <>
  <Grid container justifyContent='center' alignItems='center' sx={{ bgcolor: 'background.primary' }}>
    <Grid item xs={11}>
      <Box sx={{ mt: 2 }}>
        <SearchFrame model_name={'poitype'} populate={[]}>
          <SearchForm
            submitOnMount
            createButtonProps={{
              label: I18n.t('poitype.add')
            }}
          >
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable>
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
          </SearchTable>
        </SearchFrame>
      </Box>
    </Grid>
  </Grid>
</>


export default React.memo(PoiTypeList)
