import React, { useCallback } from 'react'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box } from '@mui/material'
import { I18n } from '@front/volcanion'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const SpecificParameterList = props => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])

  return (
    <Box className='safearea'>
      <SearchFrame model_name={'commercialpackagespecificparameters'} populate={['commercialpackage', 'specific']} >
        <SearchForm
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          submitOnMount
          createButtonProps={{
            label: I18n.t('noshow.add')
          }}
        >
          <FormContent><Form /></FormContent>
        </SearchForm>
        <SearchTable>
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
        </SearchTable>
      </SearchFrame>
    </Box>
  )
}


export default React.memo(SpecificParameterList)
