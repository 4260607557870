import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { Button } from '@mui/material'

const ButtonTableActions = (props) => {
  const selectedIds = props?.table?.selectedIds || []
  const [, { openDialog }] = hooks.useDialogs()
  const disabled = _.isEmpty(selectedIds)
  const openMultiDispatchDialog = useCallback(() => {
    openDialog('multi_dispatch', { formProps: { initialState: { order_ids: selectedIds } } })
  }, [selectedIds, openDialog])

  return (
    <Button disabled={disabled} variant={'contained'} onClick={openMultiDispatchDialog} size='small'>{I18n.t('dispatch.group.label', { count: 1 })}</Button>
  )
}

export default React.memo(ButtonTableActions)
